import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg';
import styles from './styles.module.css';
import { ProductModelDetailsQuery } from './query';
import { Tabs } from '../../components/Tabs';
import { Loader } from "../../components/Loader";
import { ProductNestedItemsTable } from "./ProductNestedItemsTable";

export const ProductModelDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<string>('assets');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { data, loading } = useQuery(ProductModelDetailsQuery, {
    variables: { id },
    initialFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });
  const product = data?.product?.data;

  /* const availableFilters = [
    { key: 'manufacturerName', label: 'Manufacturer', values: [{ key: 'Carrier', label: 'Carrier' }, { key: 'COOK', label: 'COOK' }, { key: 'DAYTON', label: 'DAYTON' }, { key: 'Halton', label: 'Halton' }] },
    { key: 'category', label: 'Category', values: [
        { key: 'Centerline Table, Cold', label: 'Centerline Table, Cold' },
        { key: 'Centerline Table, Hot', label: 'Centerline Table, Hot' },
        { key: 'Exhaust System', label: 'Exhaust System' },
        { key: 'Holding', label: 'Holding' },
        { key: 'HVAC', label: 'HVAC' },
        { key: 'Toaster, Bun', label: 'Toaster, Bun' },
        { key: 'Transfer Station/Stainless Cart', label: 'Transfer Station/Stainless Cart' },
        { key: 'Walk-in Cooler', label: 'Walk-in Cooler' },
        { key: 'Warmer', label: 'Warmer' },
    ]},
    { key: 'classification', label: 'Classification', values: [{ key: 'Cold Side', label: 'Cold Side' }, { key: 'Hot Side', label: 'Hot Side' }, { key: 'HVAC', label: 'HVAC' }, { key: 'Misc', label: 'Misc' }] },
  ]; */

  const productDetails = useMemo(
    () => ({
      name: product?.attributes.name,
      description: product?.attributes.description,
      spec: product?.attributes.specification?.data?.attributes,
      image: product?.attributes.images?.data?.[0]?.attributes.url,
      category: product?.attributes.product_category?.data?.attributes.value,
      classification: product?.attributes.product_category?.data?.attributes.product_classification?.data?.attributes.value,
      manufacturerName: product?.attributes.manufacturer?.data?.attributes.business_info?.data?.attributes.name,
      assets: product?.attributes.assets?.data || [],
      parts: product?.attributes.part_models?.data || [],
      builds: product?.attributes.builds?.data || [],
      resources: product?.attributes.resources?.data || [],
    }),
    [product],
  );

  const tabs = useMemo(() => {
    return [{
      key: 'assets',
      name: `Assets (${productDetails?.assets?.length})`,
    }, {
      key: 'parts',
      name: `Model Parts (${productDetails?.parts?.length})`,
    }, {
      key: 'builds',
      name: `Builds (${productDetails?.builds?.length})`,
    }, {
      key: 'resources',
      name: `Resources (${productDetails?.resources?.length})`,
    }];
  }, [productDetails]);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header />
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <button type="button" className={styles.backBtn} onClick={() => navigate('/product-models')}>
              <BackArrowIcon />
            </button>
            <h1>Product Model</h1>
          </div>
          <div className={styles.detailsCard}>
            {loading ? (<Loader isCentered />) : (
              <>
                <img className={styles.detailsImg} src={productDetails.image} alt="" />
                <div style={{ flex: 1 }}>
                  <div className={styles.detailsInfoHeader}>
                    <span className={styles.name}>{productDetails.name}</span>
                    <p className={styles.dimensions}>
                      H: {productDetails.spec?.height || '—'}
                      {' '}L: {productDetails.spec?.depth || '—'}
                      {' '}W: {productDetails.spec?.width || '—'}
                      {' '}W: {productDetails.spec?.weight || '—'}
                    </p>
                  </div>
                  <p className={styles.description}>
                    {productDetails.description ? productDetails.description.slice(0, isExpanded ? undefined : 200) : <em>No description</em>}
                    {productDetails.description?.length > 200 ? <span className={styles.expandToggle} onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? 'Hide details' : 'Show more'}</span> : null}
                  </p>
                  <div className={styles.detailsInfoBottom}>
                    <div className={styles.detailsParam}>
                      <p>Classification</p>
                      <span>{productDetails.classification || '—'}</span>
                    </div>
                    <div className={styles.detailsParam}>
                      <p>Category</p>
                      <span>{productDetails.category || '—'}</span>
                    </div>
                    <div className={styles.detailsParam}>
                      <p>Manufacturer</p>
                      <span>{productDetails.manufacturerName || '—'}</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div style={{ marginTop: '32px', display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
            <Tabs tabs={tabs} currentTab={currentTab} onChange={setCurrentTab} />
            <ProductNestedItemsTable productData={product?.attributes} currentTab={currentTab} />
          </div>
        </div>
      </div>
    </div>
  );
};
