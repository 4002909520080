import { useMemo, useState } from 'react';
import Table from "../../components/Table/Table";
import styles from "../product-models/styles.module.css";
import { ReactComponent as AssetIcon } from '../../assets/icons/asset.svg';

export const ProductNestedItemsTable = ({ productData, currentTab }: any) => {
  const [search, setSearch] = useState('');
  const rows = useMemo(
    () => {
      if (currentTab === 'assets') {
        return (productData?.assets?.data || []).map((asset: any) => ({
          id: asset?.id,
          name: asset?.attributes.name,
          image: asset?.attributes.images?.data?.[0]?.attributes.url,
          assetId: asset?.attributes.assetID,
          classification: productData?.product_category?.data?.attributes.product_classification?.data.attributes.value,
          manufactureDate: '-',
        })).filter((asset: any) => Object.values(asset).some((assetParam: any) => assetParam && typeof assetParam === 'string' && assetParam.includes(search)));
      }
      if (currentTab === 'parts') {
        return (productData?.part_models?.data || []).map((partModel: any) => ({
          id: partModel?.id,
          name: partModel?.attributes.name,
          image: partModel?.attributes.images?.data?.[0]?.attributes.url,
          partId: partModel?.id,
          category: productData?.product_category?.data?.attributes.product_classification?.data.attributes.value,
          status: 'Active',
        })).filter((partModel: any) => Object.values(partModel).some((partModelParam: any) => partModelParam && typeof partModelParam === 'string' && partModelParam.includes(search)));
      }
      if (currentTab === 'builds') {
        return (productData?.builds?.data || []).map((build: any) => ({
          id: build?.id,
          name: build?.attributes.name,
          image: build?.attributes.image?.data?.attributes.url,
          uniqueValue: build?.attributes.number,
        })).filter((buildModel: any) => Object.values(buildModel).some((buildModelParam: any) => buildModelParam && typeof buildModelParam === 'string' && buildModelParam.includes(search)));
      }
      if (currentTab === 'resources') {
        return (productData?.resources?.data || []).map((resource: any) => ({
          id: resource?.id,
          name: resource?.attributes.name,
          category: productData?.product_category?.data?.attributes.product_classification?.data.attributes.value,
        })).filter((resourceModel: any) => Object.values(resourceModel).some((resourceModelParam: any) => resourceModelParam && typeof resourceModelParam === 'string' && resourceModelParam.includes(search)));
      }
    },
    [productData, currentTab, search]
  );

  const columns = useMemo(
    () => {
      if (currentTab === 'assets') {
        return [
          {
            field: 'name',
            headerName: 'Asset Name',
            flex: 0.5,
            minWidth: 326,
            cellRenderer: (params: { data: { image: string }; value: string }) => (
              <div className={styles.nameField}>
                {params.data.image ? <img className="product-image" src={params.data.image} alt="" /> : <AssetIcon className="product-image" />}
                <p className="product-name">{params.value}</p>
              </div>
            ),
            pinned: 'left'
          },
          {
            field: 'assetId',
            headerName: 'ID',
            minWidth: 400,
            flex: 1,
            filterName: 'assetId',
          },
          {
            field: 'classification',
            headerName: 'Classification',
            minWidth: 200,
            flex: 1,
            filterName: 'category_classification_id',
          },
          {
            field: 'manufactureDate',
            headerName: 'Manufacture Date',
            minWidth: 360,
            flex: 1,
            filterName: 'manufactureDate',
          },
        ];
      }
      if (currentTab === 'parts') {
        return [
          {
            field: 'name',
            headerName: 'Part Name',
            flex: 0.5,
            minWidth: 326,
            cellRenderer: (params: { data: { image: string }; value: string }) => (
              <div className={styles.nameField}>
                {params.data.image ? <img className="product-image" src={params.data.image} alt="" /> : <AssetIcon className="product-image" />}
                <p className="product-name">{params.value}</p>
              </div>
            ),
            pinned: 'left'
          },
          {
            field: 'partId',
            headerName: 'ID',
            minWidth: 400,
            flex: 1,
            filterName: 'partId',
          },
          {
            field: 'category',
            headerName: 'Category',
            minWidth: 300,
            flex: 1,
            filterName: 'category',
          },
          {
            field: 'status',
            headerName: 'Status',
            minWidth: 300,
            flex: 1,
            filterName: 'status',
          },
        ];
      }
      if (currentTab === 'builds') {
        return [
          {
            field: 'name',
            headerName: 'Build Name',
            flex: 0.5,
            minWidth: 326,
            cellRenderer: (params: { data: { image: string }; value: string }) => (
              <div className={styles.nameField}>
                {params.data.image ? <img className="product-image" src={params.data.image} alt="" /> : <AssetIcon className="product-image" />}
                <p className="product-name">{params.value}</p>
              </div>
            ),
            pinned: 'left'
          },
          {
            field: 'id',
            headerName: 'ID',
            minWidth: 400,
            flex: 1,
            filterName: 'id',
          },
          {
            field: 'uniqueName',
            headerName: 'Unique Value Name',
            minWidth: 400,
            flex: 1,
            filterName: 'uniqueName',
          },
        ];
      }
      if (currentTab === 'resources') {
        return [
          {
            field: 'name',
            headerName: 'Build Name',
            minWidth: 326,
            cellRenderer: (params: { data: { image: string }; value: string }) => (
              <div className={styles.nameField}>
                {params.data.image ? <img className="product-image" src={params.data.image} alt="" /> : <AssetIcon className="product-image" />}
                <p className="product-name">{params.value}</p>
              </div>
            ),
            pinned: 'left'
          },
          {
            field: 'category',
            headerName: 'Category',
            minWidth: 400,
            flex: 1,
            filterName: 'category',
          },
        ];
      }
    },
    [currentTab]
  );

  return (
    <Table
      isLoading={false}
      onClick={(e: any) => {}}
      columns={columns}
      filters={[]}
      search={search}
      header={undefined}
      hideFilters
      onChangeSearch={setSearch}
      onChangeFilters={() => {}}
      rows={rows}
      availableFilters={[]}
    />
  );
};
