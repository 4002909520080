import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Table from '../../components/Table/Table';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg';
import { ReactComponent as OrganizationsIcon } from '../../assets/icons/organizations-icon.svg';
import styles from './styles.module.css';
import { ProductModelsListQuery } from './query';

export const ProductModelsPage = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const { data, loading } = useQuery(ProductModelsListQuery, {
    variables: { page, search },
    initialFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });

  const availableFilters = [
    { key: 'category', label: 'Category', values: [
        { key: 'Walk-in Cooler', label: 'Walk-in Cooler' },
        { key: 'Walk-in Freezer', label: 'Walk-in Freezer' },
        { key: 'Undercounter Refrigerator', label: 'Undercounter Refrigerator' },
    ]},
    { key: 'classification', label: 'Classification', values: [{ key: 'Cold Side', label: 'Cold Side' }, { key: 'Hot Side', label: 'Hot Side' }, { key: 'HVAC', label: 'HVAC' }, { key: 'Misc', label: 'Misc' }] },
  ];

  const rows = useMemo(
    () =>
      (data?.products?.data || []).map((product: any) => ({
        id: product.id,
        name: product.attributes.name,
        image: product.attributes.images?.data?.[0]?.attributes.url,
        category: product.attributes.product_category?.data?.attributes.value,
        classification: product.attributes.product_category?.data?.attributes.product_classification?.data?.attributes.value,
        manufacturerName: product.attributes.manufacturer?.data?.attributes.business_info?.data?.attributes.name,
        assetAmount: product.attributes.assets?.data?.length ?? '—',
      })),
    [data]
  );

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header />
        <div className={styles.container}>
          <Table
            isLoading={loading}
            onChangeSearch={setSearch}
            onChangeFilters={setFilters}
            search={search}
            onClick={(e: any) => navigate(`/product-model/${e.data.id}`)}
            header={(
              <div className={styles.titleContainer}>
                <button type="button" className={styles.backBtn} onClick={() => navigate(-1)}>
                  <BackArrowIcon />
                </button>
                <h1>Product Models</h1>
              </div>
            )}
            columns={[
              {
                field: 'name',
                headerName: 'Product Model',
                flex: 1,
                minWidth: 326,
                cellRenderer: (params: { data: { image: string }; value: string }) => (
                  <div className={styles.nameField}>
                    {params.data.image ? <img className="product-image" src={params.data.image} alt="" /> : <OrganizationsIcon className="product-image" />}
                    <p className="product-name">{params.value}</p>
                  </div>
                ),
                pinned: 'left'
              },
              {
                field: 'category',
                headerName: 'Category',
                minWidth: 200,
                flex: 1,
                filterName: 'category_id',
              },
              {
                field: 'classification',
                headerName: 'Classification',
                width: 200,
                filterName: 'category_classification_id',
              },
              {
                field: 'manufacturerName',
                headerName: 'Manufacturer',
                width: 360,
                filterName: 'manufacturerName',
              },
              {
                field: 'assetAmount',
                headerName: 'Asset Amount',
                width: 360,
                filterName: 'assetAmount',
              },
            ]}
            filters={filters}
            rows={rows}
            availableFilters={availableFilters}
            paginationData={data?.products?.meta?.pagination}
            onChangePage={setPage as any}
          />
        </div>
      </div>
    </div>
  );
};
