import { gql } from '@apollo/client';

export const ComponentsListQuery = gql`
    query GetComponents($page: Int, $search: String) {
        components(pagination: { page: $page, pageSize: 50 }, filters: { and: [{ name: { contains: $search } }, { name: { ne: "" } } ]}) {
            meta {
                pagination {
                    page
                    pageCount
                    total
                }
            }
            data {
                id
                attributes {
                    component_products {
                        data {
                            id
                        }
                    }
                    name
                    part_models {
                        data {
                            id
                        }
                    }
                    status
                }
            }
        }
    }
`;
