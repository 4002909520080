import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Table from '../../components/Table/Table';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg';
import { ReactComponent as ComponentIcon } from '../../assets/icons/component.svg';
import styles from './styles.module.css';
import { ComponentsListQuery } from './query';

export const ComponentsListPage = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const { data, loading } = useQuery(ComponentsListQuery, {
    variables: { page, search },
    initialFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });

  const rows = useMemo(
    () =>
      (data?.components?.data || []).map((component: any) => ({
        id: component.id,
        name: component.attributes.name,
        productsAmount: component.attributes.component_products?.data?.length || 0,
        partsAmount: component.attributes.part_models?.data?.length || 0,
        image: component.attributes.images?.data?.[0]?.attributes.url,
        type: '—',
        status: '—',
      })),
    [data]
  );

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header />
        <div className={styles.container}>
          <Table
            isLoading={loading}
            onChangeSearch={setSearch}
            onChangeFilters={setFilters}
            search={search}
            onClick={(e: any) => navigate(`/component/${e.data.id}`)}
            header={(
              <div className={styles.titleContainer}>
                <button type="button" className={styles.backBtn} onClick={() => navigate(-1)}>
                  <BackArrowIcon />
                </button>
                <h1>Components</h1>
              </div>
            )}
            columns={[
              {
                field: 'name',
                headerName: 'Component Name',
                flex: 1,
                minWidth: 326,
                cellRenderer: (params: { data: { image: string }; value: string }) => (
                  <div className={styles.nameField}>
                    {params.data.image ? <img className="product-image" src={params.data.image} alt="" /> : <ComponentIcon className="product-image" />}
                    <p className="product-name">{params.value}</p>
                  </div>
                ),
              },
              {
                field: 'type',
                headerName: 'Type',
                width: 200,
                filterName: 'type',
              },
              {
                field: 'status',
                headerName: 'Status',
                width: 200,
                filterName: 'status',
              },
              {
                field: 'productsAmount',
                headerName: 'Products',
                width: 200,
                filterName: 'productsAmount',
              },
              {
                field: 'partsAmount',
                headerName: 'Parts',
                width: 200,
                filterName: 'partsAmount',
              },
            ]}
            filters={filters}
            rows={rows}
            availableFilters={[]}
            paginationData={data?.components?.meta?.pagination}
            onChangePage={setPage as any}
            hideFilters
          />
        </div>
      </div>
    </div>
  );
};
