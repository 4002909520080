import { gql } from '@apollo/client';

export const AssetsListQuery = gql`
    query GetAssets($page: Int, $search: String) {
        assets(pagination: { page: $page, pageSize: 50 }, filters: { and: [{ name: { contains: $search } }, { name: { ne: "" } } ]}) {
            meta {
                pagination {
                    page
                    pageCount
                    total
                }
            }
            data {
                id
                attributes {
                    assetID
                    name
                    serialNumber
                    manufacturingDate
                    images {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    product {
                        data {
                            attributes {
                                name
                                productID
                                builds {
                                    data {
                                        attributes {
                                            name
                                            publishedAt
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
