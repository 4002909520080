import { gql } from '@apollo/client';

export const ComponentDetailsQuery = gql`
    query GetComponent($id: ID) {
        component(id: $id) {
            data {
                id
                attributes {
                    name
                    description
                    status
                    part_models(pagination: { page: 1, pageSize: 100 }) {
                        data {
                            id
                            attributes {
                                name
                                partNumber
                                description
                            }
                        }
                    }
                    products(pagination: { page: 1, pageSize: 100 }) {
                        data {
                            id
                            attributes {
                                name
                                productID
                                description
                                isVerified
                                assets {
                                    data {
                                        id
                                    }
                                }
                                images {
                                    data {
                                        attributes {
                                            url
                                        }
                                    }
                                }
                                manufacturer {
                                    data {
                                        id
                                        attributes {
                                            business_info {
                                                data {
                                                    id
                                                    attributes {
                                                        name
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                product_category {
                                    data {
                                        attributes {
                                            value
                                            product_classification {
                                                data {
                                                    attributes {
                                                        value
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
